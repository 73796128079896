/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Navbar from "./components/Navbar";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import CookieConsent from "react-cookie-consent"; // getCookieConsentValue, // Cookies,
import { useTranslation } from "react-i18next";
import { useEtc } from "./context/EtcContext";
import { I18nextProvider } from "react-i18next";
import { changeLanguage } from "./i18n";
import ReactGA from "react-ga";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import MenuPage from "./pages/MenuPage";
import ThePlaygroundComplex from "./pages/ThePlaygroundComplex";

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-FHBJQJ8K19");
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return <div></div>;
};

function App() {
  const {
    state,
    state: { menuToggle },
  } = useEtc();
  const location = useLocation();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    // Retrieve the language from storage on component mount
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  // Set the language in browser storage when it changes
  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
  };
  return (
    <MainDiv>
      <RouteChangeTracker />
      <I18nextProvider i18n={i18n}>
        <Suspense fallback="loading">
          {(location.pathname === "/" ||
            location.pathname === "/fr" ||
            location.pathname === "/menu" ||
            location.pathname === "/menu/fr" ||
            location.pathname === "/the-playground-complex" ||
            location.pathname === "/privacy-policy" ||
            location.pathname === "/terms-of-use") && <Navbar />}
          <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText="Ok"
            declineButtonText={i18n.language === "en" ? "Decline" : "Refuser"}
            cookieName="etc"
            cookieValue={JSON.stringify(state)}
            style={{}}
            buttonStyle={{}}
            expires={999}
            sameSite="Strict"
            secure={true}
          >
            {i18n.language === "en"
              ? "This website collects cookies to deliver better user experience"
              : "Ce site web collecte des cookies afin d'offrir une meilleure expérience utilisateur."}{" "}
            <span style={{ fontSize: "12px" }}>
              {i18n.language === "en"
                ? "We collect cookies to analyze our website traffic and performance; we never collect any personal data."
                : 'Nous collectons des témoins ("cookies") pour analyser le trafic et les performances de notre site web; nous ne collectons jamais de données personnelles.'}
            </span>
          </CookieConsent>
          {/* <BookNow
            href="https://widgets.libroreserve.com/WEB/QC016855598778/book"
            target="_blank"
          >
            {t("navbar.book")}
          </BookNow> */}
          <Language
            open={{ lng: i18n.language, menuToggle }}
            onClick={() =>
              handleLanguageChange(i18n.language === "en" ? "fr" : "en")
            }
          >
            <span>{i18n.language === "en" ? "fr" : "en"}</span>
          </Language>
          <Routes>
            <Route exact="true" path="/" element={<HomePage />} />
            <Route path="/fr" element={<HomePage />} />
            <Route path="/menu/fr" element={<MenuPage />} />
            <Route path="/menu" element={<MenuPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </I18nextProvider>
    </MainDiv>
  );
}
const MainDiv = styled.div`
  height: 100vh;
  width: 100vw;
  //temporary
  /* overflow: hidden; */
  .CookieConsent {
    background: rgba(0, 0, 0, 0.7) !important;
    color: var(--lightgrey) !important;
    & > div:first-child {
      font-family: "Beleza", sans-serif;
      font-size: 16px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      color: rgba(255, 255, 255, 0.6);
      & > span {
        margin-top: 5px;
        font-family: "Josefin Sans", sans-serif;
        color: var(--lightgrey) !important;
      }
    }
    & > div:nth-child(2) {
      align-self: center;
      & > button {
        font-family: "Josefin Sans", sans-serif;
        font-size: 16px;
        border: none;
        background: inherit !important;
        background-color: rgba(0, 0, 0, 0.2) !important;
        color: var(--lightgrey) !important;
        &:hover {
          background-color: var(--white) !important;
          color: #101010 !important;
        }
      }
      & > button:nth-child(2) {
        font-family: "Josefin Sans", sans-serif;
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(255, 255, 255, 0.6) !important;
        &:hover {
          background-color: var(--white) !important;
          color: #101010 !important;
        }
      }
    }
  }
`;
const Language = styled.div`
  z-index: 11;
  color: var(--white);
  cursor: pointer;
  position: fixed;
  /* right: ${({ open }) => (open.lng === "en" ? "180px" : "280px")}; */
  right: 180px;
  top: 20px;
  line-height: 41px;
  text-transform: uppercase;
  & > span {
    font-size: 18px;
    font-weight: normal;
  }
  & > span::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: var(--silver);
    transition: width 0.3s ease-in;
  }
  & > span:hover::after {
    width: 100%;
  }
  &:hover {
    transition: ease-in-out 0.3s;
    color: white;
    font-size: 105%;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and table styles */
    //temporary
    display: none;
    /* top: 20px;
    right: 170px; */
  }
`;
const BookNow = styled.a`
  z-index: 11;
  right: 40px;
  top: 40px;
  position: fixed;
  color: var(--white);
  border: 1px solid var(--white);
  padding: 10px 30px;
  cursor: pointer;
  border-radius: 20px;
  font-size: 18px;
  &:hover {
    transition: all 0.3s ease-in;
    background: var(--white);
    color: var(--black);
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    padding: 8px 25px;
    top: 20px;
    right: 20px;
  }
`;
export default App;
