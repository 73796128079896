export const initialState = {
  menuToggle: false,
  menu: "dinner",
  menuItemId: 4,
  reservations: [],
  foodData: [],
  cocktailsData: [],
  brunchData: [],
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case "menu-toggle":
      return { ...state, menuToggle: payload };
    case "menu":
      return { ...state, menu: payload };
    case "menu-item":
      return { ...state, menuItemId: payload };
    case "new-reservation":
      return { ...state, reservations: [payload, ...state.reservations] };
    case "SET_FOOD_DATA":
      return { ...state, foodData: payload };
    case "SET_COCKTAILS_DATA":
      return { ...state, cocktailsData: payload };
    case "SET_BRUNCH_DATA":
      return { ...state, brunchData: payload };
    default:
      return state;
  }
};
