import React from "react";
import { Helmet } from "react-helmet-async";


export const SEO = ({ item }) => {
  return (
    <Helmet>
      <title>{item.title}</title>
      <meta name="description" content={item.description} />
      <meta property="og:type" content="webapp" />
      <meta property="og:title" content={item.title} />
      {item.image && <meta property="og:image" content={item.image} />}
      <meta property="og:description" content={item.description} />
      {item.keywords && <meta property="og:keywords" content={item.keywords} />}

      {/* Add hreflang attributes for English and French versions */}
      {item.url && (
        <>
          <link rel="alternate" hreflang="en" href={item.url} />
          <link rel="alternate" hreflang="fr" href={item.url} />
          {/* <link rel="canonical" href={item.url} /> */}
        </>
      )}
    </Helmet>
  );
};
