import React, { useCallback, useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "./Img/ResponsiveImage";

const rotateRight = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

const rotateLeft = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const HomePageSecond = () => {
  const { t } = useTranslation();
  const [ref1, inView1] = useInView();
  const [animIndex, setAnimIndex] = useState(0);
  const timer = useCallback(() => {
    animIndex < 4 && setAnimIndex((prev) => prev + 1);
  }, [animIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (inView1 === true) {
        timer();
      } else {
        setAnimIndex(0);
      }
    }, 250);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <MainDiv>
      <ResponsiveImage
        src="bg_pattern.webp"
        alt="bg_pattern"
        fluidImage
        customStyles={{
          height: "100%",
          width: "100%",
          position: "absolute",
          zIndex: 0,
          top: 0,
          left: 0,
          objectFit: "cover",
        }}
      />
      <div>
        <FirstAside ref={ref1} view={{ inView1, animIndex }}>
          <ResponsiveImage src={"cycle_element.webp"} alt="circle-element" />
          <div>
            <h2>{t("h-t-h-o")}.</h2>
            <p>{t("h-t-p-o")}</p>
            <p>{t("h-t-p-t")}</p>
            {/* <a href={`#${t(`section${3}`)}`}>{t("see.menu")}</a> */}
            <div>
              <a href="/menu">{t("food-menu")}</a>
              <a href="/menu#drinks">{t("drinks-menu")}</a>
              {/* <a
                href={`/pdf/ETC_COCKTAIL_${
                  i18n.language === "en" ? "EN" : "FR"
                }.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("cocktail-menu")}
              </a>
              <a
                href="/pdf/PG_WINE_MENU.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("wine-list")}
              </a> */}
            </div>
          </div>
        </FirstAside>
        <SecondAside view={{ inView1, animIndex }}>
          <div></div>
          <ResponsiveImage src={"ETC_STEAK_01.webp"} alt="food-img" />
        </SecondAside>
      </div>
      <ElementsDiv>
        <ResponsiveImage
          src={"ETC_HOMEPAGE_ELEMENT_01.png"}
          alt="element-1"
          fludImage
        />
        <ResponsiveImage
          src={"ETC_HOMEPAGE_ELEMENT_02.png"}
          alt="element-2"
          fludImage
        />
      </ElementsDiv>
    </MainDiv>
  );
};

const MainDiv = styled.div`
  height: auto;
  min-height: 102vh;
  width: 100vw;
  background-color: var(--black);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  overflow: hidden;
  & > div:nth-child(2) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: auto;
    max-height: 600px;
    width: 100%;
    max-width: 1440px;
    gap: 40px;
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 991px) {
    overflow: hidden;
    & > div:nth-child(2) {
      padding: 80px 20px 50px;
      max-height: unset;
      flex-flow: column-reverse nowrap;
    }
  }
`;

const FirstAside = styled.aside`
  position: relative;
  width: 100%;
  max-width: 800px;
  height: auto;
  & > img {
    position: absolute;
    top: -40px;
    left: -100px;
    width: 100%;
    max-width: 288px;
    height: auto;
    transition: opacity 0.4s ease-in;
    opacity: ${({ view }) =>
      view.inView1 === true && view.animIndex !== 0 && view.animIndex > 3
        ? 1
        : 0};
  }
  & > div {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 800px;
    padding-top: 40px;
    z-index: 3;
    & > h2 {
      font-weight: lighter;
      font-size: 55px;
      margin-bottom: 12px;
      transition: opacity 0.4s ease-in;
      opacity: ${({ view }) =>
        view.inView1 === true && view.animIndex !== 0 && view.animIndex > 1
          ? 1
          : 0};
      animation: opacity 2s ease-in;
    }
    & > p {
      font-size: 16px;
      line-height: 32px;
      margin-bottom: 20px;
      transition: opacity 0.4s ease-in;
      opacity: ${({ view }) =>
        view.inView1 === true && view.animIndex !== 0 && view.animIndex > 1
          ? 1
          : 0};
    }
    & > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 40px;
      & > a {
        align-self: center;
        text-align: center;
        border: none;
        padding: 10px 25px;
        font-size: 18px;
        color: var(--white);
        border: 1px solid var(--white);
        background: none;
        outline: none;
        border-radius: 20px;
        transition: opacity 0.4s ease-in;
        opacity: ${({ view }) =>
          view.inView1 === true && view.animIndex !== 0 && view.animIndex > 2
            ? 1
            : 0};
        &:hover {
          transition: all 0.3s ease-in;
          background: var(--white);
          color: var(--black);
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    padding-left: 0;
    align-self: center;
    & > img {
      top: -30px;
      left: -40px;
      max-width: 180px;
    }
    & > div {
      max-height: unset;
      padding-top: 20px;
      top: 10px;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > h2 {
        font-size: 40px;
        margin-bottom: 10px;
        align-self: flex-start;
        margin-left: 5px;
      }
      & > p {
        line-height: 22px;
        margin-bottom: 20px;
      }
      & > div {
        grid-template-columns: 1fr;
        gap: 20px;
      }
    }
  }
`;

const SecondAside = styled.aside`
  width: 100%;
  max-width: 600px;
  height: auto;
  position: relative;
  & > img {
    animation: none;
    position: absolute;
    left: 20px;
    top: 20px;
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
    transition: opacity 0.4s ease-in;
    opacity: ${({ view }) =>
      view.inView1 === true && view.animIndex !== 0 ? 1 : 0};
  }
  & > div {
    border: 4px solid var(--primary);
    height: 100%;
    width: 100%;
    transition: opacity 0.4s ease-in;
    opacity: ${({ view }) =>
      view.inView1 === true && view.animIndex !== 0 && view.animIndex > 3
        ? 1
        : 0};
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    /* flex-basis: 2fr; */
    align-self: center;
    & > img {
      position: relative;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      object-position: 10%;
      max-height: 400px;
      left: 30px;
      width: auto;
    }
    & > div {
      border: none;
    }
  }
`;

const ElementsDiv = styled.div`
  left: 100px;
  bottom: 50px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img:first-child {
    width: 100%;
    max-width: 200px;
    height: auto;
    z-index: 1;
    animation: ${css`
      ${rotateRight} 70s linear infinite
    `};
    -webkit-filter: drop-shadow(0 1px 1px #000000);
    filter: drop-shadow(0 1px 1px #000000);
  }
  & > img:last-child {
    position: absolute;
    width: 100%;
    max-width: 100px;
    height: auto;
    z-index: 2;
    animation: ${css`
      ${rotateLeft} 70s linear infinite
    `};
    -webkit-filter: drop-shadow(0 1px 1px #000000);
    filter: drop-shadow(0 1px 1px #000000);
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    display: none;
  }
`;

export default HomePageSecond;
