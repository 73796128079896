import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  --white: #d8d9da;
  --black: #000000;
  --primary: #674a48;
  --secondary: #81605e;
  --green: #273826;
  --grey: #252626;
  --lightgrey: #747474;
}
body {
  font-family: "Josefin Sans", sans-serif;
  ul, ol {
    list-style: none;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  p {
    padding: 0;
    margin: 0;
  }
  h1, h2, h3 ,h4, h5, h6 {
    font-family: 'Belleza', sans-serif;
    text-transform: lowercase;
    padding: 0;
    margin: 0;
  }
  button {
    cursor: pointer;
    font-family: "Josefin Sans", sans-serif;
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
`;

export default GlobalStyle;
