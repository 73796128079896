import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const date = new Date();
  const year = date.getFullYear();
  return (
    <FooterDiv>
      <div>
        <div>
          <a href="/privacy-policy" rel="nofollow">{t("footer-a-o")}</a>
          <a href="/terms-of-use" rel="nofollow">{t("footer-a-t")}</a>
          <a href="https://sendy.playground.ca/subscription?f=bJP1wJRJSHeeook12I5763q3Y7nkFYx892fTd80lc9EZLgRftUut8a8Sm3Ksm9F7a98YbKCmjUhkHVlGYOayOar763bQ" target="__blank" rel="nofollow">{t("footer-a-three")}</a>
        </div>
      </div>
      {i18n.language === "en" ? (
        <div>&#169; {year} etc. All right reserved.</div>
      ) : (
        <div>&#169; {year}, etc. Tous droits réservés.</div>
      )}
      <div>
        <a
          href="https://www.facebook.com/profile.php?id=61551150300420"
          target="__blank"
        >
          <img src={"/img/icons/facebook-circular-logo.svg"} alt="facebook-icon" />
        </a>
        <a
          href="https://www.instagram.com/etc.resto/"
          target="__blank"
        >
          <img src={"/img/icons/instagram.svg"} alt="instagram-icon" />
        </a>
        <a href="https://twitter.com/etc_resto" target="__blank">
          <img src={"/img/icons/x.svg"} alt="twitter-icon" />
        </a>
        <a href="https://www.tiktok.com/@etc.resto" target="__blank">
          <img src={"/img/icons/tik-tok.svg"} alt="tiktok-icon" />
        </a>
      </div>
    </FooterDiv>
  );
};
const FooterDiv = styled.div`
  position: relative;
  bottom: 0;
  height: auto;
  /* min-height: 150px; */
  width: 100%;
  overflow-x: hidden;
  background: var(--secondary);
  color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 40px;
  & > div {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    & > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 40px;
    }
    & > a {
      & > img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        opacity: 0.7;
        &:hover {
          transition: 0.1s all;
          opacity: 1;
        }
      }
    }
    & > p {
      margin-bottom: 10px;
    }
  }
  & > div:first-child {
    justify-content: flex-start;
  }
  & > div:last-child {
    justify-content: flex-end;
  }
  @media screen and (max-width: 1240px) {
    /* start of phone and medium tablet styles */
    flex-direction: column;
    padding: 40px 20px 80px 20px;
    & > div {
      flex-direction: column;
      text-align: center;
      justify-content: center;
      width: 100%;
      & > div {
        flex-direction: column;
        gap: 40px;
      }
    }
    & > div:last-child {
      flex-direction: row;
      justify-content: center;
    }
  }
`;
export default Footer;
