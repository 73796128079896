import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
// import { v4 as uuidv4 } from "uuid";
import { useEtc } from "../context/EtcContext";
import DrinksContent from "./DrinksContent";
import FoodContent from "./FoodContent";
import { useTranslation } from "react-i18next";

const menuData = [
  {
    id: 1,
    title: "Lobster Bisque",
    content: "Lorem, ipsum dolor sit amet consectetur",
    price: "14",
    category: "apetizers",
    section: "menu",
    image1: "/img/STEAK_02.webp",
    image2: "/img/cooked_steak.webp",
  },
  {
    id: 2,
    title: "Fried Calamari",
    content: "Sit amet consectetur adipisicing",
    price: "25",
    category: "apetizers",
    section: "menu",
    image1: "/img/STEAK_03.webp",
    image2: "/img/cooked_steak-03.webp",
  },
  {
    id: 3,
    title: "Salmon Tartare",
    content: "Ipsum dolor sit amet consectetur adip",
    price: "19",
    category: "apetizers",
    section: "menu",
    image1: "/img/STEAK_02.webp",
    image2: "/img/cooked_steak.webp",
  },
  {
    id: 4,
    title: "NY Style Sirloin 6 OZ",
    content: "amet consectetur adipisicing",
    price: "43",
    category: "steak",
    section: "menu",
    image1: "/img/STEAK_03.webp",
    image2: "/img/cooked_steak-03.webp",
  },
  {
    id: 5,
    title: "Filet Mignot Center-Cut 8 0Z",
    content: "Sit amet consetur hhhggghh adip",
    price: "57",
    category: "steak",
    section: "menu",
    image1: "/img/STEAK_02.webp",
    image2: "/img/cooked_steak.webp",
  },
  {
    id: 6,
    title: "Rib Steak, Bone-In 16 OZ",
    content: "Ipsum dolor sit amet consectetur adip",
    price: "65",
    category: "steak",
    section: "menu",
    image1: "/img/STEAK_03.webp",
    image2: "/img/cooked_steak-03.webp",
  },
];
// const menuLinks = ["Group", "Brunch", "Menu", "Drink", "Alcohol"];
const HomePageThird = () => {
  const { i18n } = useTranslation();
  const {
    state: { menu },
    dispatch,
  } = useEtc();
  const [switchBtn] = useState("RAW");
  // const handleMenuItem = (value) => {
  //   dispatch({ type: "menu-item", payload: value });
  // };
  const handleMenuHeader = (value) => {
    dispatch({ type: "menu", payload: value });
  };
  // let foundIndex = menuData.findIndex((el) => el.id === menuItemId);

  return (
    <MainDiv>
      <img src={menuData[0].image1} alt="menu-item" />
      <Menu>
        <aside>
          <ToggleButton toggle={switchBtn}>
            <input
              className="switch-button-checkbox"
              type="checkbox"
              // onClick={() =>
              //   setSwitchBtn((prev) => (prev === "RAW" ? "COOKED" : "RAW"))
              // }
              id="raw/cooked"
            ></input>
            <label className="switch-button-label" htmlFor="raw/cooked">
              <span className="switch-button-label-span">RAW</span>
            </label>
          </ToggleButton>
        </aside>
        <aside>
          <div>
            <img src={"/img/ETC_ele_light_02.webp"} alt="element-one" />
            <img src={"/img/ETC_ele_light_01.webp"} alt="element-two" />
          </div>
          <div>
            <img src={"/img/ETC_ele_light_02.webp"} alt="element-one" />
            <img src={"/img/ETC_ele_light_01.webp"} alt="element-two" />
          </div>
          <Main key={menu}>
            <ul>
              {/* {menuLinks.map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleMenuHeader(item)}
                  className={menu === item ? "active-nav" : undefined}
                >
                  <h1
                    className={menu === item ? "active-nav-header" : undefined}
                  >
                    <span
                      className={menu === item ? "active-nav-span" : undefined}
                    >
                      {item}
                    </span>
                  </h1>
                </li>
              ))} */}
              <li
                onClick={() => handleMenuHeader("food")}
                className={menu === "food" ? "active-nav" : undefined}
              >
                <h1
                  className={menu === "food" ? "active-nav-header" : undefined}
                >
                  <span
                    className={menu === "food" ? "active-nav-span" : undefined}
                  >
                    {i18n.language === "en" ? "Food" : "Repas"}
                  </span>
                </h1>
              </li>
              <li
                onClick={() => handleMenuHeader("drinks")}
                className={menu === "drinks" ? "active-nav" : undefined}
              >
                <h1
                  className={
                    menu === "drinks" ? "active-nav-header" : undefined
                  }
                >
                  <span
                    className={
                      menu === "drinks" ? "active-nav-span" : undefined
                    }
                  >
                    {i18n.language === "en" ? "Drinks" : "Boissons"}
                  </span>
                </h1>
              </li>
            </ul>
            {menu === "drinks" ? <DrinksContent /> : null}
            {menu === "food" ? <FoodContent /> : null}
          </Main>
        </aside>
      </Menu>
    </MainDiv>
  );
};
const rotateRight = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;
const rotateLeft = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const MainDiv = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--black);
  & > img:first-child {
    position: absolute;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    object-position: center;
    animation: ${fadeIn} 0.3s ease-in-out;
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
    display: block;
    top: 0;
    & > img:first-child {
      height: calc(100% - 340px);
      object-position: 0%;
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    display: block;
    overflow: hidden;
    top: 0;
    & > img:first-child {
      height: calc(100% - 315px);
      object-position: 10%;
    }
  }
`;
const ToggleButton = styled.div`
  pointer-events: none;
  border: 1px solid var(--white);
  border-radius: 20px;
  overflow: hidden;
  width: 240px;
  text-align: center;
  font-size: 20px;
  position: relative;
  padding-right: 120px;
  background: rgba(0, 0, 0, 0.1);
  left: -80px;
  color: ${({ toggle }) =>
    toggle === "COOKED" ? "var(--white)" : "var(--black)"};
  transition: 300ms all;
  display: none;
  &:before {
    content: "COOKED";
    position: absolute;
    top: 5px;
    bottom: 0;
    right: 2px;
    width: 120px;
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
    color: ${({ toggle }) =>
      toggle === "RAW" ? "var(--white)" : "var(--black)"};
  }
  input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    &:checked + .switch-button-label:before {
      transform: translateX(110px);
      transition: transform 300ms linear;
    }
    &:checked + .switch-button-label-span:after {
      color: black;
    }
    & + .switch-button-label {
      position: relative;
      padding: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      pointer-events: none;
      &:before {
        content: "";
        background: rgba(255, 255, 255, 0.8);
        height: 80%;
        width: 100%;
        position: absolute;
        left: 5px;
        margin-bottom: 1px;
        border-radius: 20px;
        transform: translateX(0);
        transition: transform 300ms;
      }
      .switch-button-label-span {
        position: relative;
        top: 3px;
        left: 5px;
        margin-bottom: 1px;
      }
      &:checked + .switch-button-label-span:before {
        color: white;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    font-size: 18px;
    width: 220px;
    position: relative;
    left: 50%;
    top: 80px;
    transform: translateX(-50%);
    &:before {
      content: "COOKED";
      position: absolute;
      bottom: 0;
      right: 2px;
      width: 100px;
    }
  }
`;
const Menu = styled.div`
  height: 100%;
  max-height: 700px;
  width: 100%;
  max-width: 1100px;
  display: flex;
  padding: 40px;
  & > aside:first-child {
    width: 100%;
    height: 100%;
    position: relative;
  }
  & > aside:last-child {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background: var(--primary);
    & > div:first-child {
      position: relative;
      width: 100%;
      max-width: 400px;
      height: auto;
      left: -160px;
      top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img:first-child {
        position: absolute;
        height: auto;
        width: 100%;
        max-width: 400px;
        animation: ${rotateRight} 70s linear infinite;
        z-index: 1;
      }
      & > img:last-child {
        position: absolute;
        height: auto;
        width: 100%;
        max-width: 200px;
        animation: ${rotateLeft} 70s linear infinite;
        z-index: 2;
      }
    }
    & > div:nth-child(2) {
      position: absolute;
      width: 100%;
      max-width: 400px;
      height: auto;
      right: -160px;
      bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img:first-child {
        position: absolute;
        height: auto;
        width: 100%;
        max-width: 400px;
        animation: ${rotateRight} 70s linear infinite;
        z-index: 1;
      }
      & > img:last-child {
        position: absolute;
        height: auto;
        width: 100%;
        max-width: 200px;
        animation: ${rotateLeft} 70s linear infinite;
        z-index: 2;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    flex-flow: column nowrap;
    padding: 0;
    max-height: 100vh;
    & > aside:first-child {
    }
    & > aside:last-child {
      flex-flow: column-reverse wrap;
      max-height: 340px;
      & > div:first-child {
        top: unset;
        position: absolute;
        bottom: 30px;
        & > img:first-child {
          max-width: 280px;
        }
        & > img:last-child {
          max-width: 140px;
        }
      }
      & > div:nth-child(2) {
        bottom: unset;
        top: 30px;
        & > img:first-child {
          max-width: 280px;
        }
        & > img:last-child {
          max-width: 130px;
        }
      }
    }
  }
`;
const Main = styled.main`
  position: relative;
  height: 100%;
  width: 100%;
  color: var(--white);
  z-index: 3;
  padding: 40px;
  & > ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: space-evenly;
    position: relative;
    height: 20px;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    padding: 0 20px;
    /* .active-nav {
      scale: 1.4;
    } */
    & > li {
      position: relative;
      transition: all 0.1s linear;
      cursor: pointer;
      text-align: center;
      & > h1 {
        font-weight: lighter;
        /* font-size: 22px; */
        font-size: 28px;
        width: 100%;
        & > span {
          width: 100%;
          max-width: 90px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 1px;
            bottom: 0;
            left: 0;
            background-color: var(--white);
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
          &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
          &.active-nav-span {
            border-bottom: 1px solid var(--white);
            &:hover::after {
              transform: scaleX(0);
              transform-origin: bottom left;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and tablet styles */
    display: flex;
    flex-direction: column-reverse;
    padding: 40px 20px;
    & > ul {
      padding: 0;
      & > li {
        & > h1 {
          font-size: 20px;
          /* &.active-nav-header {
            & > span {
              font-size: 16px;
            }
          } */
        }
      }
    }
  }
`;
// const MenuContent = styled.div`
//   height: 100%;
//   max-height: 580px;
//   width: 100%;
//   max-width: 700px;
//   margin: 0 auto;
//   padding: 40px 20px;
//   & > div {
//     width: 100%;
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     gap: 5px;
//     margin-bottom: 35px;
//     overflow-y: scroll;
//     scroll-behavior: smooth;
//     ::-webkit-scrollbar {
//       width: 2px;
//     }
//     /* Track */
//     ::-webkit-scrollbar-track {
//       border-radius: 20px;
//     }
//     /* Handle */
//     ::-webkit-scrollbar-thumb {
//       background: rgba(255, 255, 255, 0.3);
//       border-radius: 20px;
//     }
//     /* Handle on hover */
//     /* ::-webkit-scrollbar-thumb:hover {
//     background: var(--lightgrey);
//   } */
//     & > h3 {
//       text-align: center;
//       font-weight: 500;
//       margin-top: 5px;
//     }
//     & > div {
//       display: flex;
//       flex-direction: row;
//       justify-content: space-between;
//       align-items: center;
//       /* cursor: pointer; */
//       padding: 16px 8px;
//       margin-right: 8px;
//       transition: 0.4s all;
//       & > span {
//         & > span {
//           font-size: 18px;
//           color: var(--white);
//         }
//         & > p {
//           font-weight: lighter;
//           font-size: 16px;
//         }
//       }
//     }
//     /* .active-item {
//       background: var(--secondary);
//       border-radius: 10px;
//     } */
//   }
//   @media screen and (max-width: 991px) {
//     /* start of phone and tablet styles */
//     padding: 0 20px;
//     & > div {
//       max-height: 220px;
//       & > h3 {
//         font-size: 22px;
//         margin-bottom: 10px;
//       }
//       & > div {
//         padding: 6px;
//         & > span {
//           & > span {
//             font-size: 16px;
//           }
//           & > p {
//             font-size: 14px;
//           }
//         }
//       }
//     }
//   }
// `;
export default HomePageThird;
