import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Footer from "../components/Footer";
import { Noindex } from "../Noindex";

const TermsOfUse = () => {
  const { t } = useTranslation();

  return (
    <TermsOfUseDiv>
      <div>
        <h1>{t("termsAndConditions")}</h1>

        <h3>{t("introduction")}</h3>

        <p>{t("introductionText")}</p>

        <h3>{t("intellectualProperty")}</h3>

        <p>{t("intellectualPropertyText")}</p>

        <h3>{t("limitationsOfUse")}</h3>

        <p>{t("limitationsOfUseText1")}</p>
        <ul>
          <li>{t("limitationsOfUseText2")}</li>
          <li>{t("limitationsOfUseText3")}</li>
          <li>{t("limitationsOfUseText4")}</li>
        </ul>

        <h3>{t("userContent")}</h3>

        <p>{t("userContentText")}</p>

        <h3>{t("limitationsOfLiability")}</h3>

        <p>{t("limitationsOfLiabilityText")}</p>

        <h3>{t("reservationPolicy")}</h3>

        <p>{t("reservationPolicyText1")}</p>
        <p>{t("reservationPolicyText2")}</p>

        <h3>{t("privacyPolicy")}</h3>

        <p>
          {t("privacyPolicyText")} <a href="##">{t("privacyPolicyLink")}</a>
        </p>

        <h3>{t("changesToTerms")}</h3>

        <p>{t("changesToTermsText")}</p>

        <h3>{t("contactUs")}</h3>

        <p>
          {t("contactUsText")} <a href="##">{t("contactUsLink")}</a>
        </p>
      </div>
      <Footer />
      <Noindex />
    </TermsOfUseDiv>
  );
};

const TermsOfUseDiv = styled.div`
  width: 100%;
  height: auto;
  overflow-x: hidden;
  background: var(--primary);
  & > div:first-child {
    color: var(--white);
    padding: 100px 20px;
    margin: 0 auto;
    height: auto;
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    & > h1 {
      margin-bottom: 12px;
      font-size: 55px;
      font-weight: lighter;
    }
    & > h3 {
      font-size: 30px;
      padding-bottom: 10px;
      font-weight: lighter;
    }
    & > ul {
      & > li {
        font-size: 16px;
        line-height: 32px;
        margin-bottom: 20px;
      }
    }
    & > p {
      font-size: 16px;
      line-height: 32px;
    }
  }
`;
export default TermsOfUse;
