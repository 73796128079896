import { useState, useEffect } from "react";

const useResponsiveImage = (desktopSrc, mobileSrc, breakpoint = 991) => {
  const [imageSrc, setImageSrc] = useState(
    window.innerWidth > breakpoint ? desktopSrc : mobileSrc
  );

  useEffect(() => {
    const handleResize = () => {
      setImageSrc(
        window.innerWidth > breakpoint ? desktopSrc : mobileSrc
      );
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [desktopSrc, mobileSrc, breakpoint]);

  return imageSrc;
};

export default useResponsiveImage;
