import { createContext, useContext, useEffect, useReducer } from "react";
import { initialState, reducer } from "../reducer";
import { fetchBrunch, fetchCocktails, fetchFood } from "../utils/csvUtils";

export const EtcContext = createContext();
export const EtcProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [foodDataArray, cocktailsDataArray, brunchDataArray] =
          await Promise.all([fetchFood(), fetchCocktails(), fetchBrunch()]);
        dispatch({ type: "SET_FOOD_DATA", payload: foodDataArray });
        dispatch({ type: "SET_COCKTAILS_DATA", payload: cocktailsDataArray });
        dispatch({ type: "SET_BRUNCH_DATA", payload: brunchDataArray });
      } catch (error) {
        console.error("Encountered an error:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once on mount
  return (
    <EtcContext.Provider value={{ state, dispatch }}>
      {children}
    </EtcContext.Provider>
  );
};
export const useEtc = () => useContext(EtcContext);
