import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "./Img/ResponsiveImage";
import useResponsiveImage from "../utils/useResponsiveImage";

const AerialView = () => {
  const { t } = useTranslation();
  const imageSrc = useResponsiveImage(
    "etc-satellite-view-desktop.webp",
    "etc-satellite-view-mobile.webp"
  );

  return (
    <AerialViewDiv>
      <ResponsiveImage
        src={imageSrc}
        alt="Aerial View"
        fluidImage
        customStyles={{
          height: "100%",
          width: "100%",
          position: "absolute",
          zIndex: 1,
          top: 0,
          left: 0,
          objectFit: "cover",
        }}
      />
      <div>
        <h2>{t("aerial-h-o")}</h2>
        <p>{t("aerial-p-o")}</p>
      </div>
    </AerialViewDiv>
  );
};

const AerialViewDiv = styled.div`
  height: auto;
  min-height: 100vh;
  width: 100vw;
  position: relative;
  top: 0;
  padding: 40px;
  & > img {
    object-position: left;
  }
  & > div {
    position: absolute;
    left: 4%;
    bottom: 4%;
    z-index: 4;
    width: 100%;
    max-width: 500px;
    color: var(--white);
    text-align: left;
    padding: 0 20px;
    & > h2 {
      margin-bottom: 12px;
      font-size: 55px;
      font-weight: lighter;
      z-index: 4;
      position: relative;
    }
    & > p {
      font-size: 16px;
      line-height: 32px;
      z-index: 4;
      position: relative;
    }
  }
  @media screen and (max-width: 991px) {
    display: block;
    padding: 60px 20px;
    & > img {
      object-position: center;
      height: 100%;
    }
    & > div {
      position: unset;
      max-width: unset;
      transform: none;
      padding: 0;
      padding-top: 125vw;
      & > h2 {
        font-size: 40px;
        margin: 50px 0 10px 0;
        align-self: flex-start;
        margin-left: 5px;
      }
      & > p {
        line-height: 22px;
      }
    }
  }
  @media screen and (min-width: 479px) and (max-width: 991px) {
    & > div {
      padding-top: 80vw;
    }
  }
`;

export default AerialView;
