import React from "react";
import styled, { keyframes, css } from "styled-components";
import GoogleMap from "./GoogleMap";
// import { v4 as uuidv4 } from "uuid";
import { useInView } from "react-intersection-observer";
import { HiLocationMarker, HiPhone, HiMail, HiClock } from "react-icons/hi";
import { BiUpArrowAlt } from "react-icons/bi";
import { useEtc } from "../context/EtcContext";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "./Img/ResponsiveImage";

const HomePageFive = () => {
  const { t, i18n } = useTranslation();
  // const [inputValues, setInputValues] = React.useState({
  //   _id: uuidv4(),
  //   name: "",
  //   email: "",
  //   comments: "",
  // });
  const { dispatch } = useEtc();
  const [ref5, inView5] = useInView();
  // const inputChange = (e) => {
  //   setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  // };
  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch({
  //     type: "new-reservation",
  //     payload: inputValues,
  //   });
  //   setInputValues({ _id: uuidv4(), name: "", email: "", comments: "" });
  // };
  return (
    <MainDiv ref={ref5}>
      {inView5 ? (
        <ArrowLink
          view5={inView5}
          href={`#${t("section1")}`}
          onClick={() => dispatch({ type: "bouncy-down-arrow", payload: 1 })}
        >
          <BiUpArrowAlt />
          <div></div>
        </ArrowLink>
      ) : null}
      <main>
        {/* <h2>{t("contact.header")}</h2> */}
        <div>
          <aside>
            <div>
              <GoogleMap samesite="lax" />
            </div>
            <a
              href="https://www.google.com/maps/dir//Playground+1500+QC-138+Unit+C+Kahnawake,+QC+J0L+1B0/@45.3769003,-73.7061295,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4cc91bfa372112f3:0x84343f2ce23c89ba!2m2!1d-73.7061295!2d45.3769003?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              {i18n.language === "en"
                ? "Get driving drections"
                : "Itinéraire pour s'y rendre"}
            </a>
          </aside>
          <aside>
            <h2>{t("contact.header")}</h2>
            <div>
              <HiClock />
              <span>
                {" "}
                <span>{t("contact.hours")}:</span>
                {i18n.language === "en" ? (
                  <>
                    <p>Sunday – Thursday: 5:00PM – 11:00PM</p>
                    <p>Friday – Saturday: 5:00PM – 12:00AM</p>
                    {/* <p>Weekend Brunch</p>
                    <p>10:00AM - 2:00PM</p> */}
                  </>
                ) : (
                  <>
                    <p>Dimanche au jeudi : 17h00 – 23h00</p>
                    <p>Vendredi et samedi : 17h00 – 00h00</p>
                    {/* <p>Brunch du week-end</p>
                    <p>10:00 - 14:00</p> */}
                  </>
                )}
              </span>
            </div>
            <div>
              <HiLocationMarker />
              <span>
                {" "}
                <span>{t("contact.location")}:</span>
                {i18n.language === "en" ? (
                  <p>1500 Unit C, QC-138</p>
                ) : (
                  <p>1500 Unité C, QC-138</p>
                )}
                <p>Kahnawake, QC</p>
                <p>J0L 1B0</p>
              </span>
            </div>
            <div>
              <HiPhone />
              <span>
                {" "}
                <a href="tel:14506356963">1-450-635-6963</a>
              </span>
            </div>
            <div>
              <HiMail />
              <span>
                {" "}
                <a href="mailto:info@playgroundetc.ca">info@playgroundetc.ca</a>
              </span>
            </div>
            {/* <Form onSubmit={onSubmit}>
              <div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="NAME"
                  value={inputValues.name}
                  onChange={inputChange}
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="EMAIL"
                  value={inputValues.email}
                  onChange={inputChange}
                  required
                />
              </div>
              <textarea
                name="comments"
                id="comments"
                cols="10"
                rows="5"
                placeholder="COMMENTS"
                value={inputValues.comments}
                onChange={inputChange}
              ></textarea>
              <button type="submit">Send</button>
            </Form> */}
          </aside>
        </div>
      </main>
      <ElementDiv>
        <div>
          <ResponsiveImage
            src={"ETC_ele_light_02.webp"}
            alt="element-2"
          />
          <ResponsiveImage
            src={"ETC_ele_light_01.webp"}
            alt="element-1"
          />
        </div>
      </ElementDiv>
    </MainDiv>
  );
};
const rotateRight = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
  `;
const rotateLeft = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
  `;
const bounce = keyframes`0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-15px);}
	60% {transform: translateY(-8px);}`;

const ArrowLink = styled.a`
  position: fixed;
  z-index: 11;
  text-decoration: none;
  animation: ${bounce} 2s ease infinite;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  ${({ view5 }) =>
    view5 === true
      ? css`
          top: 4%;
        `
      : css`
          bottom: 4%;
        `};
  font-size: 30px;
  border: 2px solid lightgray;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: var(--white);
  cursor: pointer;
  & > div {
    margin-top: 15px;
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    top: unset;
    bottom: 20px;
  }
`;
const MainDiv = styled.div`
  height: 100vh;
  width: 100vw;
  background: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  position: relative;
  overflow: hidden;
  & > main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 40px;
    & > h2 {
      color: var(--white);
      font-weight: lighter;
      font-size: 50px;
      margin: 0 0 50px 0;
    }
    & > div {
      height: 100%;
      max-height: 500px;
      width: 100%;
      max-width: 1100px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      & > aside:first-child {
        height: 100%;
        width: 100%;
        max-width: 750px;
        max-height: 550px;
        & > div {
          position: relative;
          height: 100%;
          min-height: 250px;
          width: 100%;
          border: 3px solid var(--white);
          & > iframe {
            position: absolute;
            left: 25px;
            top: 25px;
            height: 100%;
            width: 100%;
            z-index: 3;
          }
        }
        & > a {
          z-index: 5;
          position: relative;
          display: flex;
          justify-content: center;
          font-size: 18px;
          background: inherit;
          outline: none;
          transition: 0.3s all;
          padding: 10px 0;
          max-width: 250px;
          cursor: pointer;
          border-radius: 20px;
          border: none;
          border: 1px solid var(--white);
          color: var(--white);
          margin: 40px auto;
          &:hover {
            transition: all 0.3s ease-in;
            background: var(--white);
            color: var(--black);
          }
          @media screen and (max-width: 479px) {
            /* start of phone styles */
            padding: 8px 25px;
          }
          &:hover {
            background: var(--white);
            color: var(--black);
          }
        }
      }
      & > aside:last-child {
        height: 100%;
        width: 100%;
        max-width: 450px;
        max-height: 500px;
        color: var(--white);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 24px;
        & > h2 {
          color: var(--white);
          font-weight: lighter;
          margin: 0 0 50px 0;
          margin-bottom: 12px;
          font-size: 55px;
          font-weight: lighter;
        }
        & > div {
          display: flex;
          align-items: flex-start;
          & > span {
            margin-left: 20px;
            & > div {
              margin-top: 10px;
            }
            & > span {
              font-family: "Belleza", sans-serif;
              font-size: 22px;
              line-height: 16px;
            }
            & > p:nth-child(2) {
              margin-top: 10px;
            }
            & > p {
              margin-bottom: 10px;
            }
          }
        }
        & > div:nth-child(2) {
          & > span:nth-child(2) {
            & > p:last-child {
              margin-bottom: 0;
            }
          }
        }
        & > div:nth-child(5) {
          align-items: flex-start;
        }
      }
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
    & > main {
      & > div {
        & > aside:last-child {
          & > h2 {
            /* display: none; */
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    padding: 40px 20px 80px 20px;
    height: auto;
    & > main {
      padding: 0;
      & > h2 {
        display: none;
      }
      & > div {
        height: auto;
        max-width: unset;
        max-height: unset;
        flex-direction: column-reverse;
        justify-content: space-between;
        gap: 20px;
        & > aside:first-child {
          max-height: 350px;
          padding-right: 0;
          padding-top: 20px;
          & > div {
            & > iframe {
              left: 10px;
              top: 10px;
            }
          }
          & > a {
            max-width: 260px;
          }
        }
        & > aside:last-child {
          display: block;
          max-height: unset;
          height: 100%;
          & > h2 {
            color: var(--white);
            font-weight: lighter;
            font-size: 40px;
            text-align: center;
            margin: 10px;
            margin-bottom: 20px;
          }
          & > div {
            color: rgba(255, 255, 255, 0.6);
            align-items: flex-start;
            & > span {
              margin-left: 10px;
              font-size: 16px;
              line-height: 22px;
              & > p {
              }
              /* & > p:nth-child(2) {
                margin-top: 5px;
              }
              & > p {
                margin-bottom: 5px;
              } */
            }
          }
          & > div:nth-child(2),
          & > div:nth-child(4),
          & > div:nth-child(5) {
            margin-bottom: 32px;
          }
        }
      }
    }
  }
`;
// const Form = styled.form`
//   height: 100%;
//   width: 100%;
//   align-self: flex-start;
//   display: flex;
//   flex-flow: column nowrap;
//   gap: 20px;
//   z-index: 4;
//   position: relative;
//   & > div:nth-child(1),
//   div:nth-child(2),
//   div:nth-child(3) {
//     display: flex;
//     flex-flow: row nowrap;
//     justify-content: space-between;
//     align-items: center;
//     gap: 10px;
//     input {
//       background: var(--secondary);
//       color: var(--white);
//       text-transform: lowercase;
//       font-size: 16px;
//       font-family: "Josefin Sans", sans-serif;
//       border: none;
//       outline: none;
//       width: 100%;
//       line-height: 50px;
//       padding-left: 10px;
//       text-shadow: none;
//       &::placeholder {
//         color: rgba(255, 255, 255, 0.7);
//       }
//       &:-ms-input-placeholder {
//         color: rgba(255, 255, 255, 0.7);
//       }
//       &::-ms-input-placeholder {
//         color: rgba(255, 255, 255, 0.7);
//       }
//       &:focus::placeholder {
//         color: rgba(255, 255, 255, 1);
//       }
//     }
//   }
//   & > textarea {
//     background: var(--secondary);
//     color: silver;
//     text-transform: lowercase;
//     font-size: 16px;
//     font-family: "Josefin Sans", sans-serif;
//     border: none;
//     outline: none;
//     width: 100%;
//     padding: 5px;
//     padding-left: 10px;
//     resize: none;
//     line-height: 25px;
//     text-shadow: none;
//     &::placeholder {
//       color: rgba(255, 255, 255, 0.7);
//     }
//     &:-ms-input-placeholder {
//       color: rgba(255, 255, 255, 0.7);
//     }
//     &::-ms-input-placeholder {
//       color: rgba(255, 255, 255, 0.7);
//     }
//     &:focus::placeholder {
//       color: rgba(255, 255, 255, 1);
//     }
//   }
//   & > button {
//     z-index: 5;
//     position: absolute;
//     bottom: 20px;
//     right: 20px;
//     align-self: flex-end;
//     font-size: 18px;
//     background: inherit;
//     outline: none;
//     transition: 0.3s all;
//     padding: 10px 30px;
//     cursor: pointer;
//     border-radius: 20px;
//     border: none;
//     border: 1px solid var(--white);
//     color: var(--white);
//     &:hover {
//       transition: all 0.3s ease-in;
//       background: var(--white);
//       color: var(--black);
//     }
//     @media screen and (max-width: 479px) {
//       /* start of phone styles */
//       padding: 8px 25px;
//     }
//     &:hover {
//       background: var(--white);
//       color: var(--black);
//     }
//   }
//   @media screen and (min-width: 992px) {
//     /* start of desktop styles */
//   }
//   @media screen and (max-width: 991px) {
//     /* start of phone and medium tablet styles */
//     align-items: unset;
//     max-height: 200px;
//     gap: 32px;
//     & > div:nth-child(1),
//     div:nth-child(2),
//     div:nth-child(3) {
//       & > input {
//         line-height: 30px;
//       }
//     }
//     & > textarea {
//       line-height: 18px;
//     }
//     button {
//       bottom: 10px;
//       right: 10px;
//     }
//   }
// `;
const ElementDiv = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  max-height: 700px;
  max-width: 700px;
  bottom: -200px;
  right: -200px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    position: relative;
    width: 100%;
    max-width: 700px;
    height: auto;
    max-width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img:first-child {
      position: absolute;
      height: auto;
      width: 100%;
      max-width: 700px;
      animation: ${rotateRight} 70s linear infinite;
      z-index: 1;
    }
    & > img:last-child {
      position: absolute;
      height: auto;
      width: 100%;
      max-width: 350px;
      animation: ${rotateLeft} 70s linear infinite;
      z-index: 2;
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
    max-width: 500px;
    max-height: 500px;
    bottom: -200px;
    right: -200px;
    & > div {
      max-width: 500px;
      max-height: 500px;
      & > img:first-child {
        max-width: 500px;
      }
      & > img:last-child {
        max-width: 250px;
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    max-width: 300px;
    max-height: 300px;
    bottom: -150px;
    right: -150px;
    & > div {
      max-width: 300px;
      max-height: 300px;
      & > img:first-child {
        max-width: 300px;
      }
      & > img:last-child {
        max-width: 150px;
      }
    }
  }
`;
export default HomePageFive;
