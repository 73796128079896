import React, { useCallback, useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { useTranslation } from "react-i18next";
import { SEO } from "../SEO";
import ResponsiveImage from "./Img/ResponsiveImage";

const HomePageFirst = () => {
  const [index, setIndex] = useState(1);
  const [flag, setFlag] = useState(false);
  const { t } = useTranslation();
  const imageFadeFn = useCallback(() => {
    index < 2 ? setIndex((prev) => prev + 1) : setIndex(1);
    setFlag(true);
  }, [index]);
  useEffect(() => {
    const sliderInterval = setInterval(() => {
      imageFadeFn();
    }, 3000);
    return () => {
      clearInterval(sliderInterval);
    };
  });
  return (
    <MainDiv flag={flag}>
      <img src={"/img/logos/logo.webp"} alt="etc-logo" />
      <ResponsiveImage
        key={index}
        src={`etc-home-desktop_0${index}.webp`}
        alt="bg-img"
        fluidImage
        customStyles={{
          height: "100vh",
          width: "100vw",
          position: "absolute",
          objectPosition: "center",
          objectFit: "cover",
        }}
      />
      <div>
        <h1>{t("h-o-h-o")}</h1>
        <p>{t("h-o-p-o")}</p>
      </div>
      <div>
        <img src={"/img/ETC_HOMEPAGE_ELEMENT_01.png"} alt="element-one" />
      </div>
      <div>
        <img src={"/img/PLAYGROUND-LOGO.svg"} alt="pg-logo" />
      </div>
      <h2>{t("coming.soon")}</h2>
      <div>
        <img src={"/img/ETC_HOMEPAGE_ELEMENT_02.png"} alt="element-two" />
      </div>
      <SEO
        item={{
          title: t("seo-h-o-h-o"),
          description: t("seo-h-o-p-o"),
          url: "https://playgroundetc.ca/",
          image: "/seo_images/etc-mediteranean-steakhouse.jpg",
        }}
      />
    </MainDiv>
  );
};
const rotateRight = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;
const rotateLeft = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const MainDiv = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  background-color: var(--black);
  & > img:first-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    width: 100%;
    max-width: 210px;
    animation: ${fadeIn} 0.8s ease-in;
  }
  & > img:nth-child(2) {
    ${({ flag }) =>
      flag === true &&
      css`
        animation: ${fadeIn} 0.8s linear;
      `};
  }
  & > div:nth-child(3) {
    /* display: none; */
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -50%);
    z-index: 4;
    width: 100%;
    max-width: 700px;
    animation: ${fadeIn} 0.8s ease-in;
    color: var(--white);
    text-align: center;
    padding: 0 20px;
    & > h1 {
      margin-bottom: 12px;
      font-size: 55px;
      font-weight: lighter;
    }
    & > p {
      margin: 0 auto;
      max-width: 500px;
      font-size: 16px;
      line-height: 32px;
    }
    & > a {
      position: relative;
      top: 20px;
      align-self: center;
      text-align: center;
      border: none;
      padding: 10px 25px;
      font-size: 18px;
      color: var(--white);
      border: 1px solid var(--white);
      background: none;
      outline: none;
      border-radius: 20px;
      transition: opacity 0.4s ease-in;
      &:hover {
        transition: all 0.3s ease-in;
        background: var(--white);
        color: var(--black);
      }
    }
  }
  & > div:nth-child(4) {
    position: absolute;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${fadeIn} 0.8s ease-in;
    & > img {
      height: auto;
      width: 100%;
      max-width: 380px;
      animation: ${rotateRight} 70s linear infinite;
    }
  }
  & > div:nth-child(5) {
    display: none;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    width: 100%;
    max-width: 200px;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  & > h2:nth-child(6) {
    display: none;
    position: absolute;
    left: 50%;
    top: 75%;
    transform: translate(-50%, -25%);
    z-index: 5;
    color: var(--white);
    font-size: 42px;
    text-transform: capitalize;
  }
  & > div:nth-child(7) {
    position: absolute;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${fadeIn} 0.8s ease-in;
    & > img {
      height: auto;
      width: 100%;
      max-width: 190px;
      animation: ${rotateLeft} 70s linear infinite;
    }
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
    & > img:nth-child(2) {
      object-fit: cover;
    }
  }
  //temporary width size from 479 to 991
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    & > img:first-child {
      max-width: 180px;
      padding-bottom: 10px;
    }
    /* & > img:nth-child(2) {
      object-fit: cover;
    } */
    & > div:nth-child(3) {
      & > h1 {
        font-size: 40px;
        margin: 10px 0;
        align-self: flex-start;
        margin-left: 5px;
        line-height: 36px;
      }
      & > p {
        line-height: 22px;
        margin-bottom: 20px;
      }
    }
    & > div:nth-child(4) {
      & > img {
        max-width: 320px;
        margin-bottom: 10px;
      }
    }
    & > div:nth-child(5) {
      max-width: 150px;
    }
    & > h2:nth-child(6) {
      width: 100%;
      text-align: center;
      font-size: 25px;
      /* margin-top: 20px; */
    }
    & > div:nth-child(7) {
      & > img {
        max-width: 160px;
        margin-bottom: 10px;
      }
    }
  }
  /* @media screen and (max-width: 375px) {
    & > div:nth-child(3) {
      top: 75%;
    }
  } */
`;
export default HomePageFirst;
