/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import HomePageFirst from "../components/HomePageFirst";
import ContactUs from "../components/ContactUs";
import Gallery from "../components/Gallery";
import About from "../components/About";
import Menu from "../components/Menu";
import { useEtc } from "../context/EtcContext";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import AerialView from "../components/AerialView";
import { useLocation } from "react-router-dom";
// import PopUpBox from "../components/PopUpBox";

const HomePage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  useLayoutEffect(() => {
    const section = document.getElementById(`${location.hash.substring(1)}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.hash]);
  const {
    state: { menuToggle },
  } = useEtc();
  return (
    <HomePageDiv open={menuToggle}>
      {/* <PopUpBox /> */}
      <section id={t("section1")}>
        <HomePageFirst />
      </section>
      <section id={t("section2")}>
        <About />
      </section>
      {/* <section id={t('section3')}>
        <Menu />
      </section> */}
      <section id={t("section4")}>
        <Gallery />
      </section>
      <section id={t("section5")}>
        <AerialView />
      </section>
      <section id={t("section6")}>
        <ContactUs />
      </section>
      <section id="footer">
        <Footer />
      </section>
    </HomePageDiv>
  );
};
const HomePageDiv = styled.div`
  height: 100vh;
  width: 100vw;
  scroll-behavior: smooth;
  overflow-y: ${({ open }) => (open === true ? "hidden" : "scroll")};
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    overflow-x: hidden;
  }
`;
export default HomePage;
