import Papa from 'papaparse';

// URL to your CSV file
const foodCSV = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSOYX9XwNobY0pQDSapwAwAPEGfLmxggofkmzzreN5ucpkqYu_aIACZJxbI1wJ6a0Lzwsi6u3OdIdnP/pub?gid=363998093&single=true&output=csv';

// Arrow function to fetch and parse the CSV data
export const fetchFood = () => {
    return fetch(foodCSV)
        .then(response => response.text()) // Convert to text
        .then(csvText => {
            // Parse CSV text to JSON using PapaParse
            return new Promise((resolve, reject) => {
                Papa.parse(csvText, {
                    header: true, // Indicates that the first row of the CSV are headers
                    complete: (results) => {
                        resolve(results.data); // Resolve with parsed data
                    },
                    error: (error) => {
                        reject(error); // Reject on error
                    }
                });
            });
        })
        .catch(error => {
            console.error('Error fetching and parsing CSV:', error);
            throw error;  // Re-throw to be handled by the consumer of the function
        });
};

// URL to your CSV file
const cocktailsCSV = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSOYX9XwNobY0pQDSapwAwAPEGfLmxggofkmzzreN5ucpkqYu_aIACZJxbI1wJ6a0Lzwsi6u3OdIdnP/pub?gid=423724112&single=true&output=csv';

// Arrow function to fetch and parse the CSV data
export const fetchCocktails = () => {
    return fetch(cocktailsCSV)
        .then(response => response.text()) // Convert to text
        .then(csvText => {
            // Parse CSV text to JSON using PapaParse
            return new Promise((resolve, reject) => {
                Papa.parse(csvText, {
                    header: true, // Indicates that the first row of the CSV are headers
                    complete: (results) => {
                        resolve(results.data); // Resolve with parsed data
                    },
                    error: (error) => {
                        reject(error); // Reject on error
                    }
                });
            });
        })
        .catch(error => {
            console.error('Error fetching and parsing CSV:', error);
            throw error;  // Re-throw to be handled by the consumer of the function
        });
};

// URL to your CSV file
const brunchCSV = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSOYX9XwNobY0pQDSapwAwAPEGfLmxggofkmzzreN5ucpkqYu_aIACZJxbI1wJ6a0Lzwsi6u3OdIdnP/pub?gid=579958270&single=true&output=csv';

// Arrow function to fetch and parse the CSV data
export const fetchBrunch = () => {
    return fetch(brunchCSV)
        .then(response => response.text()) // Convert to text
        .then(csvText => {
            // Parse CSV text to JSON using PapaParse
            return new Promise((resolve, reject) => {
                Papa.parse(csvText, {
                    header: true, // Indicates that the first row of the CSV are headers
                    complete: (results) => {
                        resolve(results.data); // Resolve with parsed data
                    },
                    error: (error) => {
                        reject(error); // Reject on error
                    }
                });
            });
        })
        .catch(error => {
            console.error('Error fetching and parsing CSV:', error);
            throw error;  // Re-throw to be handled by the consumer of the function
        });
};
