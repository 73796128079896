/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import styled, { keyframes } from "styled-components";
import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIos,
} from "react-icons/md";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "./Img/ResponsiveImage";

const imageGallery = [
  "gallery/14-cuts-of-steak.webp",
  // require("gallery/dj.webp"),
  "gallery/drinks.webp",
  "gallery/pastries.webp",
  // require("gallery/dj.webp"),
];
const imageGallerMobile = [
  "gallery/etc-14-cuts-of-steak-mobile.webp",
  // require("gallery/etc-dj-mobile.webp"),
  "gallery/drinks-mobile.webp",
  "gallery/etc-pastries-mobile.webp",
  // require("gallery/etc-dj-mobile.webp"),
];

const HomePageFourth = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const gallerySlide = useCallback(() => {
    activeIndex < 3 ? setActiveIndex((prev) => prev + 1) : setActiveIndex(0);
  }, [activeIndex]);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     gallerySlide();
  //   }, 3000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [gallerySlide]);
  const arrowSlide = (action) => {
    if (action === "left") {
      activeIndex > 0
        ? setActiveIndex((prev) => prev - 1)
        : setActiveIndex(imageGallery.length - 1);
    } else {
      activeIndex < imageGallery.length - 1
        ? setActiveIndex((prev) => prev + 1)
        : setActiveIndex(0);
    }
  };
  const getImageGallery = () => {
    return windowWidth >= 768 ? imageGallery : imageGallerMobile;
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <MainDiv>
      <AsideTwo>
        <div>
          <ResponsiveImage
            key={activeIndex}
            src={getImageGallery()[1]}
            alt="bg"
          />
        </div>
        {/* <ul>
          <li className={activeIndex === 0 ? "active-circle" : undefined}>
            <span onClick={() => setActiveIndex(0)}></span>
          </li>
          <li className={activeIndex === 1 ? "active-circle" : undefined}>
            <span onClick={() => setActiveIndex(1)}></span>
          </li>
          <li className={activeIndex === 2 ? "active-circle" : undefined}>
            <span onClick={() => setActiveIndex(2)}></span>
          </li>
          <li className={activeIndex === 3 ? "active-circle" : undefined}>
            <span onClick={() => setActiveIndex(3)}></span>
          </li>
        </ul> */}
        <div>
          {/* <span onClick={() => arrowSlide("left")}>
            <MdOutlineArrowBackIos />
          </span>
          <span onClick={() => arrowSlide("right")}>
            <MdOutlineArrowForwardIos />
          </span> */}
        </div>
      </AsideTwo>
      <AsideOne>
        <div>
          <h1>{t("gallery-h")}</h1>
          <p>{t("gallery-p")}</p>
          <p>{t("gallery-p2")}</p>
        </div>
        <div>
          <ResponsiveImage
            src="ETC_HOMEPAGE_ELEMENT_01.png"
            alt="element-one"
          />
        </div>
        <div>
          <ResponsiveImage
            src="ETC_HOMEPAGE_ELEMENT_02.png"
            alt="element-two"
          />
        </div>
      </AsideOne>
    </MainDiv>
  );
};
const rotateRight = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;
const rotateLeft = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const MainDiv = styled.div`
  height: 100vh;
  width: 100vw;
  background: var(--black);
  color: var(--white);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    width: 100%;
    height: auto;
    display: block;
  }
`;
const AsideOne = styled.aside`
  height: 100%;
  width: 100%;
  max-width: 450px;
  max-height: 500px;
  position: absolute;
  left: 4%;
  padding: 20px;
  top: 25%;
  & > div:first-child {
    position: relative;
    z-index: 4;
    & > h1 {
      font-weight: lighter;
      font-size: 55px;
      margin-bottom: 12px;
    }
    & > p {
      width: 100%;
      max-width: 530px;
      font-size: 16px;
      line-height: 32px;
      padding-bottom: 15px;
    }
  }
  & > div:nth-child(2) {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 34%;
    left: 62%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      height: auto;
      width: 100%;
      max-width: 380px;
      animation: ${rotateRight} 70s linear infinite;
      -webkit-filter: drop-shadow(0 1px 1px #000000);
      filter: drop-shadow(0 1px 1px #000000);
    }
  }
  & > div:last-child {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    top: 34%;
    left: 62%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      height: auto;
      width: 100%;
      max-width: 190px;
      animation: ${rotateLeft} 70s linear infinite;
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (max-width: 991px) {
    max-width: unset;
    max-height: unset;
    height: auto;
    position: relative;
    padding: 40px;
    left: 0;
    & > div:first-child {
      position: relative;
      width: 100%;
      left: 0;
      right: 0;
      transform: unset;
      bottom: 0;
      & > h1 {
        font-size: 40px;
        margin-bottom: 10px;
      }
      & > p {
        max-width: none;
        font-size: 16px;
        line-height: 22px;
      }
    }
    & > div:nth-child(2) {
      top: 40px;
      left: 25%;
      transform: translate(-45%, -50%);
      height: auto;
      & > img {
        max-width: 230px;
        height: auto;
      }
    }
    & > div:last-child {
      top: 40px;
      left: 25%;
      transform: translate(-45%, -50%);
      height: auto;
      & > img {
        max-width: 110px;
        height: auto;
      }
    }
  }
  @media screen and (max-width: 479px) {
    padding: 40px 20px;
  }
`;
const AsideTwo = styled.aside`
  width: 100%;
  height: 100%;
  position: relative;
  & > div:first-child {
    height: 100%;
    width: 100%;
    position: relative;
    & > img {
      position: absolute;
      width: 100vw;
      height: 100vh;
      /* object-position: -400px 0; */
      object-fit: cover;
      clip-path: polygon(26% 0%, 100% 0%, 100% 100%, 46% 100%);
      animation: ${fadeIn} 0.4s ease-in;
    }
  }
  & > ul {
    position: absolute;
    bottom: 5%;
    right: 18%;
    transform: translate(-50%, -50%);
    z-index: 5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 22px;
    width: 100%;
    max-width: 200px;
    color: var(--white);
    & > li {
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      animation: border 0.8s ease-in;
      &.active-circle {
        border: 1px solid var(--white);
      }
      &:hover {
        border: 1px solid var(--white);
      }
      & > span {
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.7);
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
    & > div:last-child {
      display: none;
    }
  }
  @media screen and (max-width: 1300px) {
    & > div:first-child {
      & > img {
        clip-path: polygon(40% 0, 100% 0, 100% 100%, 60% 100%);
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    /* position: absolute;
    max-height: calc(100% - 220px);
    top: 0;
    left: 0;
    bottom: 0; */
    height: auto;
    position: relative;
    & > div:first-child {
      position: relative;
      & > img {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 92%);
        object-position: center;
        object-fit: contain;
        top: 0;
        height: auto;
        min-height: 460px;
        position: relative;
      }
    }
    & > ul {
      bottom: unset;
      position: relative;
      top: -20px;
      left: 50%;
      transform: none;
      transform: translateX(-50%);
    }
    & > div:last-child {
      z-index: 4;
      position: relative;
      bottom: unset;
      top: -38px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 22px;
      width: 100%;
      max-width: 300px;
      & > span {
        font-size: 25px;
      }
    }
  }
  @media screen and (max-width: 479px) {
    & > div:first-child {
      & > img {
        min-height: 390px;
      }
    }
  }
`;
export default HomePageFourth;
