import React from "react";
import Sphere from "../components/Sphere";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";

const ThePlaygroundComplex = () => {
  const { t } = useTranslation();
  return (
    <MainDiv>
      <ContentDiv>
        <ThePgComplexContent>
          <div>
            <h2>{t("pg-complex-h")}</h2>
            <p>{t("pg-complex-p")}</p>
          </div>
          <Sphere />
        </ThePgComplexContent>
      </ContentDiv>
      <Footer />
    </MainDiv>
  );
};
const MainDiv = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  color: var(--white);
  background-color: var(--black);
  background-image: url("/img/bg_pattern.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 1240px) {
    /* start of phone and medium tablet styles */
    height: auto;
    overflow-y: scroll;
  }
`;
const ContentDiv = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  @media screen and (max-width: 1240px) {
    /* start of phone and medium tablet styles */
    padding: 100px 20px 20px 20px;
  }
`;
const ThePgComplexContent = styled.div`
  position: relative;
  height: 100%;
  width: 85%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  padding: 30px;
  border-radius: 20px;
  & > div {
    flex-basis: 40%;
    & > h2 {
      margin-bottom: 12px;
      font-size: 55px;
      font-weight: lighter;
    }
    & > p {
      font-size: 16px;
      line-height: 32px;
    }
  }
  @media screen and (max-width: 1240px) {
    /* start of phone and medium tablet styles */
    /* flex-flow: column nowrap; */
    display: block;
    padding: 0;
    width: 100%;
    /* gap: 20px; */
    & > div {
      flex-flow: column nowrap;
      flex-basis: 100%;
      & > h2 {
        font-size: 40px;
        margin: 10px 0;
        align-self: flex-start;
        margin-left: 5px;
        line-height: 36px;
      }
      & > p {
        line-height: 22px;
        margin-bottom: 20px;
      }
    }
  }
`;
export default ThePlaygroundComplex;
